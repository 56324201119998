import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Select from 'react-select'
import Layout from "../components/layout"
import * as styles from "./index.module.css"
import jogging from "../images/jogging.png"
import lightbulb from "../images/lightbulb.svg"
import news from "../images/news.svg"
import googleplay from "../images/fi_badge_web_generic.png"
//import msstore from "../images/Finnish_get-it-from-MS.png"
import loadable from '@loadable/component'
import CookieConsent from "react-cookie-consent";

const PWAPrompt = loadable(() => import("react-ios-pwa-prompt"))

var formatGroupLabel = data => (
  <div className={styles.groupMain}>
    <span>{data.label}</span>
    <span className={styles.groupBadge}>{data.options.length}</span>
  </div>
);

const customStyles = {
  control: base => ({
    ...base,
    height: 60,
    minHeight: 45,
    fontSize: 18,
    marginTop: 10
  })
};

var ownRestaurant = typeof window !== 'undefined' ? (localStorage.getItem('ownRestaurant') ? localStorage.getItem('ownRestaurant').split(';#') : []) : [];

const IndexPage = () => {

  const [appInterface, setAppInterface] = useState(true);

  if (typeof window !== 'undefined' && !window.location.href.includes('/?stay')) {
    if (ownRestaurant.length === 1) {
      window.location.href = `/menu/${ownRestaurant[0].split('|')[0]}/`;
    }
  }

  useEffect(() => {
    try {
      if (window) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
      }
    } catch (e) {
      console.error(e);
    }

    if (
      !(
        navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
      )
    ) {
      setAppInterface(false);
    }
  }, [])

  const data = useStaticQuery(graphql`
    query Restaurants {
      allAzureJson(filter: {WeekMenu: {elemMatch: {RestaurantId: {ne: null}}}}) {
        nodes {
          WeekMenu {
            RestaurantId
            RestaurantName
            CityName
          }
        }
      }
    }
    `)

  const getGroupedOptions = () => {
    let tempGo = [];

    // cities
    data.allAzureJson.nodes.forEach(node => {
      if (tempGo.findIndex(x => x.label === node.WeekMenu[0].CityName) === -1) {
        tempGo.push(
          {
            label: node.WeekMenu[0].CityName
          });
      }
    });

    tempGo.sort((a, b) => a.label.localeCompare(b.label, 'fi'));

    tempGo.forEach(city => {
      var restaurants = [];

      data.allAzureJson.nodes.filter(c => c.WeekMenu[0].CityName === city.label).forEach(node => {
        let restaurantShortName = node.WeekMenu[0].RestaurantName.replace(`${node.WeekMenu[0].CityName} - `, '');
        if (restaurants.findIndex(x => x.label === restaurantShortName) === -1) {
          restaurants.push({
            "label": restaurantShortName,
            "value": `/menu/${node.WeekMenu[0].RestaurantId}/`
          });
        }
      });

      city.options = restaurants.sort((a, b) => a.label.localeCompare(b.label, 'fi'));
    })

    return tempGo;
  }

  const [groupedOptions] = useState(() => getGroupedOptions());

  const filterOption = ({ label, value }, string) => {
    label = label.toLocaleLowerCase(); string = string.toLocaleLowerCase();

    // default search
    if (label.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = groupedOptions.filter(group =>
      group.label.toLocaleLowerCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find(opt => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  const gotoOwnRestaurant = (id) => {
    window.location.href = `/menu/${id}/`;
  }

  const handleKeyDown = (evt) => {
    switch (evt.key) {
      case "Home": //evt.preventDefault();
        if (evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0, 0);
        break;
      case "End": //evt.preventDefault();
        const len = evt.target.value.length;
        if (evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len, len);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <SEO title="Intin ruokalistat nopeasti" description="Näe ruokalistat nopeasti ja helposti." />

      <Select
        className={styles.selectBox}
        placeholder="Hae ruokalan nimellä..."
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        filterOption={filterOption}
        styles={customStyles}
        onChange={(value, action) => window.location.href = `${value.value}`}
        openMenuOnClick={false}
        noOptionsMessage={() => "Ei löytynyt..."}
        onKeyDown={handleKeyDown}
      />

      {ownRestaurant ?
        ownRestaurant.map(r => {
          let rArr = r.split('|');
          return (
            <button className={styles.shortcut} onClick={() => gotoOwnRestaurant(rArr[0])}><img src={jogging} alt='Pikalinkki' />{rArr[1]}</button>);
        })
        : <></>
      }

      <div className={styles.welcomeContainer}>
        <div className={`${styles.welcomeText} ${styles.yellow}`}>
          <h3><img src={lightbulb} alt='Hehkulamppu' />Tiesitkö?</h3>
          {/* <p>
            <strong>Miten kalaäidit kutovat villapaitoja lapsilleen?</strong><br />
            <span id="vastaus" style={{ display: 'none' }}>Kalapuikoilla <span role="img" aria-labelledby="jsx-a11y/accessible-emoji">🤣🤣🤣</span></span><button id="vButton" onClick={() => { document.getElementById('vButton').style.display = 'none'; document.getElementById('vastaus').style.display = 'block' }}>Katso vastaus</button>
          </p> */}
          <strong>Vinkkejä</strong><br />
          <ul>
            <li>Pääset etusivulle yläreunan logosta.</li>
            <li>Voit valita useamman suosikkiravintolan. Jos valitset vain yhden, sinut ohjataan aina suoraan sen ravintolan ruokalistasivulle.</li>
          </ul>
          <p>
            <strong>Voit myös tallentaa pikalinkin suoraan tämän viikon ruokalistaan, osoite ei koskaan muutu.</strong><br />
            Vaikkapa RISE Oulun kuluvan viikon ruokalista on aina osoitteessa <a href="https://inttiruoka.fi/menu/riseoulu_ruokalista" target="_blank" rel="noreferrer">https://inttiruoka.fi/menu/riseoulu_ruokalista</a>.
          </p>
        </div>
        <div className={styles.welcomeText}>
          <h3><img src={news} alt='Puhekupla' />Uutiset</h3>
          <p>
          <strong>16.11.2021</strong><br />
            Lisätty tuki useammalle suosikkiravintolalle.
          </p>
          <p>
          <strong>11.11.2021</strong><br />
            Parannettu ruokalistojen ulkoasua.
          </p>
          <p>
          <strong>2.11.2021</strong><br />
            Helpotettu sovelluksen asentamista iPhoneille.
          </p>
        </div>
      </div>

      <div id='adbanner' className={styles.adBannerContainer}>
        <ins className="adsbygoogle"
          data-ad-client="ca-pub-2668838512868610"
          data-ad-slot="2714921034"
          data-ad-format="horizontal,auto"
          data-full-width-responsive="true"></ins>
      </div>

      {!appInterface ?
        <div className={styles.appButtons}>
          <a href={`https://play.google.com/store/apps/details?id=fi.inttiruoka&utm_source=${appInterface ? 'app' : 'web'}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`} target="_blank" rel="noreferrer">
            <img alt='Get it on Google Play' src={googleplay} className={styles.google} />
          </a>
          <br />
        </div> : ''}

      <PWAPrompt
        copyTitle='Lisää aloitusnäyttöön'
        copyBody='Voit sen jälkeen käynnistää sovelluksen nopeasti ja helposti aloitusvalikosta.'
        copyShareButtonLabel='1) Napauta Jaa-kuvaketta'
        copyAddHomeButtonLabel='2) Napauta "Lisää aloitusnäyttöön"'
        copyClosePrompt='Sulje'
        debug={false} />

      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="kouluruokafi_cookie_consent"
        containerClasses={styles.cookieConsent}
      >
        Palvelu käyttää evästeitä toiminnallisuuksiin ja palvelun parantamiseen. Käyttämällä palvelua hyväksyt <Link to='/info'>tietosuojaselosteemme</Link> sekä evästeiden käytön.
      </CookieConsent>
    </Layout>
  )
};

export default IndexPage
