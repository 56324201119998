// extracted by mini-css-extract-plugin
export var adBannerContainer = "index-module--adBannerContainer--e3f2f";
export var appButtons = "index-module--appButtons--2eb21";
export var cookieConsent = "index-module--cookieConsent--37a00";
export var google = "index-module--google--daae8";
export var groupBadge = "index-module--groupBadge--10bc5";
export var groupMain = "index-module--groupMain--ca2c9";
export var ms = "index-module--ms--e9a4f";
export var selectBox = "index-module--selectBox--4e5c7";
export var shortcut = "index-module--shortcut--f9512";
export var welcomeContainer = "index-module--welcomeContainer--434a6";
export var welcomeText = "index-module--welcomeText--5b94f";
export var yellow = "index-module--yellow--9c5e2";